.home-wrapper {
  padding: 16px; // Adds padding around the main content area
  padding-top: 0;
}

.dashboard-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  padding: 16px 0;
  margin-bottom: 32px;
  z-index: 100;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  gap: 20px;

  .header-logo {
    width: 30px;
    height: auto;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
    text-align: left;
  }
}

.filter-capsules {
  display: flex;
  gap: 12px; // Adds spacing between capsules
  margin-bottom: 24px; // Adds space below the filters and above the cards
  justify-content: center;
}

.filter-button {
  border-radius: 20px;
}

.ant-row {
  row-gap: 24px; // Adds vertical space between rows of cards
  column-gap: 16px; // Adds horizontal space between columns of cards
}

.pagination-wrapper {
  margin-top: 24px;
  margin-bottom: 24px;
}
