// colors
$whiteColor: white;
$blackColor: black;
$yellowColor: #f0c010;
$blueColor: #0c3cb4;
$greyColor: #474545;
$boulder: #757575;
$starDust: #9C9C9E;
$viola: #0000001F;
$nevada: #646566;
$osloGray: #828384;
$monsoonColor: #888888;
$blueStoneColor: #006064;
$dodgerBlue: #31A6FF;
$terracottaColor: #e18660;
$torchRed: #FF0044;
$mauve: #D9B8FF;
$concreteColor: #f2f2f2;
$alabasterColor: #fafafa;
$galleryColor: #efefef;
$linkWaterColor: #fbfcfe;
$pacificBlueColor: #0c9ab9;
$tradeWind: #6ABBBD;
$easternBlue: #1593A0;
$silverSand: #B7B8B9;
$jellyBean: #2893A0;
$catskillWhite: #EAF3F5;
$dawnPink: #EAECF0;
$wedgeWood:#44919E;
$lochinvarColor: #308c8f;
$blueIvy: #3386d4;
$cornflowerBlue: #625DF5;
$lightGreyColor: #faf9f6;
$silverColor: #cac9c9;
$sunsetOrangeColor: #ff4d4f;
$smokeyColor: #f8f8f8;
$disableGrayColor: #DADADA;
$cloudColor: #c4c4c4;
$greenWhiteColor: #e8e8e8;
$aquaSqueeze: #E8F3F3;
$coolGrey: #8FA4A6;
$alto: #DEDEDE;
$tundora: #4e4e4e;
$ziggurat: #bedcdf;
$wildSand: #F4F4F4;
$desertStormColor: #F7F7F7;
$lavenderPinocchio: #E0E0E0;
$darkCyanColor: #018786;
$aquaSqueeze: #E0F2F2;
$cloudyBlue: rgba(73, 148, 153, 0.07);
$dullOrangeColor: #D98436;
$fuzzyWuzzyBrown: #C15749;
$forestGreen: #3D9062;
$ceruleanBlue: #2440BA;
$greyGoose: #CED2D9;
$plantationColor: #245257;
$ironsideGreyColor: #666666;
$hippieBlue: #59A3A5;
$seaColor: #499499;
$twilightBlue: #F1FDFF;
$rossoCorsa: #D71900;
$whiteSmoke: #F5F5F5;
$carbonGrey: #5C5C5C;
$silver: #cccccc;
$silverDark: #BCBCBC;
$catskillWhite: rgba(255, 255, 255, 0.8);
$milkWhite: #FBFCFE;
$porcelain: #F6F9F9;
$tarawera: #07454C;
$alabaster: #FAFAFA;
$mercury: #E8E8E8;
$lochinvar: rgba(48, 140, 143, 0.8);
$gallery: #EDEDED;
$dovegrey: #707070;
$persianRed: #d04437;
$sunShade: #ffa726;
$justRight: #EFD6BE;
$perano: #A4BEF7;
$electricViolet: #513ffd;
$sushi: #8BC34A;
$boulder: #777777;
$vistaWhite: #F9F0F0;
$oysterPink: #E8C7C7;
$blackSqueeze: #F0F7F9;
$regentBlue: #A8D6E3;
$selago: #F2F6FE;
$spindle: #ADC2EB;
$borderColor: #E8E8E9;
$silverChalice: #B2B2B2;
$pacificBlue: #0C9AB9;
$mercuryLight: #E2E2E2;
$iron: #CFD0D1;
$porcelainLight: #F3F5F6;
$backLogTitleBackground: #0022620F;
$tagsBackgroundColor: #E7F5F8;
$shepraBlue: #004655;
$deepGreen: rgba(7, 102, 123, 0.1);
$thinBlue: rgba(0, 34, 98, 0.06);
$shadowBlack: rgba(black, 0.05);
$silverGray: rgba(black, 0.3);
$sortOptionColor: rgba(33, 33, 33);
$athensGray: #F9FAFB;
$paleGrey: #FBFBFC;
$amour: #FBF0F3;
$torchRed: #FF0044;
$gray: #909090;
$deepSeaGreen: #0B5F68;
$shamrock: #39D477;
$blackHaze: #F8F9F9;
$oldRose: #C272C9;
$oldRoseLight: rgba($oldRose, 0.4);
$kleinBlue: #1330B2;
$kleinBlueLight: rgba($kleinBlue, 0.4);
$pastelGreen: rgba(21, 147, 160);
$loblolly: #ECF7F9;

// element's colors
$textBoxColor: $lavenderPinocchio;
$ironsideGreyColor: $ironsideGreyColor;
$lightModalColor: #01a39d;
$dashboardHeadingColor: $blueStoneColor;
$dashboardSecondaryColor: #f24e1e;
$postFileButtonColor: $terracottaColor;
$dashboardModalColor: $darkCyanColor;
$lineColor: #80deea;
$feedBackgroundColor: #e5e5e5;
$googleButtonColor: rgb(82, 116, 219);
$replyTextColor: $cloudColor;
$packagesButtonColor: #e0f7fa;
$packageClickedButtonColor: #abeaf2;
$landingFooterBgColor: #28262c;
$landingFooterTextColor: #efedf1;
$landingPageTextColor: rgb(21, 147, 160);
$landingPageHeadingsColor: #433d4b;
$landingPageSubHeadingColor: rgba(50.551, 52.452, 55.07, 0.83);
$mineShaft: #3F3F3F;
$landingPageTextHoverColor: rgb(9, 64, 70);
$landingPageWorkflowBgColor: $whiteSmoke;
$landingPageHeadingColor: #433d4b;
$commentFeedLineColor: $greenWhiteColor;
$onboardingBgColor: $desertStormColor;
$contractSigningModalColor: #aaaaaa;
$postVersionModalColor: #01a39d;
$loginLinksColor: #32a6ff;
$errorColor: $sunsetOrangeColor;
$notesBackgroundColor: $linkWaterColor;
$notesTextColor: rgba(0, 0, 0, 0.54);
$notesButtonColor: $easternBlue;
$notesBorderColor: #a6a6a6;
// Dashboard New Design Color
$primaryDashboardButtonColor: $terracottaColor;
$primaryDashboardButtonHoverColor: #c0653f;
$secondaryDashboardButtonColor: $pacificBlueColor;
$secondaryDashboardDisabledButtonColor: $coolGrey;
$projectThumbnailColor: rgba(0, 0, 0, 0.87);
$primaryWorkspaceButtonColor: $easternBlue;
$primaryWorkspaceButtonPressColor: $plantationColor;
$primaryWorkspaceButtonHoverColor: #297780;
$almostBlackColor: #00000066;
$primaryTransparentButtonHoverColor: rgba(73, 148, 153, 0.07);
$notificationDateColor: rgba(0, 0, 0, 0.6);
$milestoneStepTextColor: rgba(0, 0, 0, 0.4);
// Add Project New Design Color
$addProjectOptionNumberBgColor: $lochinvarColor;
$addProjectOptionBgColor: rgba(73, 148, 153, 0.12);
$addProjectDatePickerColor: rgba(1, 135, 134, 0.4);
$addProjectDateSelectorColor: #0b5155;
// Post File Modal New Design Color
$figmaInputColor: rgba(0, 0, 0, 0.38);
$primaryButtonBorderColor: rgba(0, 0, 0, 0.08);
$inviteModalPlaceholderColor: rgba(0, 0, 0, 0.38);
$catskillWhite: rgba(243, 245, 246, 0.5);
// Milestone Screen Colors
$workspaceNavbarBackgroundColor: $alabasterColor;
$dashboardNavbarBackgroundColor: $desertStormColor;
$dashboardPrimaryColor: $easternBlue;
$milestoneDateTimelineColor: rgba(0, 0, 0, 0.18);
// Workspace Screen Colors
$workspaceSidebarBackgroundColor: $aquaSqueeze;
$workspaceThumbnailBorderColor: $concreteColor;
// Deposit Screen Color
$depositDenyButtonColor: $fuzzyWuzzyBrown;
$depositDenyButtonHoverColor: #964a40;
$attentionAlertBackgroundColor: $dullOrangeColor;
$depositDividerColor: $greyGoose;
$fontFamily: 'Montserrat', sans-serif;

@mixin textStyle($fontSize, $fontWeight, $fontColor) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $fontColor;
}

@mixin flexDisplay($display, $justifyContent, $alignItems) {
  display: $display;
  justify-content: $justifyContent;
  align-items: $alignItems;
}
