.reviewBox {
  height: 440px;
  border-radius: 8px;
  transition: all 0.3s ease;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e8e8e8;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-4px);
    border-color: #1890ff;
  }
}

.divider {
  margin: 15px 0;
}

.image {
  width: 100%;
  height: 180px; // Slightly taller image for better visibility
  object-fit: cover;
  border-bottom: 2px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  background-color: #f9f9f9;
}

.cardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
  display: flex;
  align-items: center;
}

.template {
  font-size: 0.85rem;
  color: #888;
}

.metadata {
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding: 12px;
  border-top: 1px solid #f0f0f0;
  background-color: #fafafa;
  border-radius: 0 0 8px 8px;

  b {
    font-weight: 500;
    color: #333;
  }

  .ant-typography {
    display: block;
    margin-bottom: 4px;
  }
}
