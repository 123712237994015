@use '../../Variable' as styles;
@use 'styles/screen_size.scss' as screenSize;
@use 'styles/z_index.scss' as zIndexSize;

.login__form {
  @include styles.flexDisplay(flex, flex-start, flex-start);
  flex-direction: column;
}

.login__buttonContainer {
  display: flex;
  padding: 12px 16px;
  justify-content: flex-start;
  align-items: center;
  flex: 0 auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-radius: 4px;
  background-color: styles.$googleButtonColor;
  transition: transform 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    transform: translate(0px, -5px);
  }
}

.login__googleLogo {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: styles.$whiteColor;
  padding: 4px;
}

.login__buttonText {
  @extend .loginButton;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  letter-spacing: 0;
  background-color: styles.$googleButtonColor;
  width: 80%;
}

.login__spinner {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
}

.hide {
  display: none;
}

.login__session_expired {
  width: 100%;
  &.login__session_expired_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .login__login__session_expired__header {
    font-size: 58px;
    margin-bottom: 20px;
    letter-spacing: 0.2px;
    color: styles.$landingPageHeadingColor;
    font-weight: 400;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 44px;
      letter-spacing: 0px;
    }
  }

  .login__session_expired__buttonsBlock {
    @include styles.flexDisplay(flex, space-between, center);
    height: 100%;
  }
}
