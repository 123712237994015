.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.breadcrumb {
  margin-bottom: 20px;
}

.columns {
  display: flex;
}

.canvas-column {
  flex: 0 0 60%;
  padding-right: 20px;
  position: sticky;
  top: 0;
  height: calc(100vh - 85px);
  overflow: hidden;
}

.feedback-column {
  flex: 0 0 40%;
  overflow-y: auto;
  max-height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
}

canvas {
  display: block;
  border: 1px solid black;
  image-rendering: crisp-edges;
}

.feedback-list {
  margin-top: 20px;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  flex: 1;
  overflow-y: auto;
}

.feedback-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  }
}

.feedback-item-error {
  border: solid 1px #EA4335;
  background-color: #ea433508;
}

.feedback-item-suggestion {
  border: solid 1px #ffba00;
  background-color: rgb(255 186 0 / 6%);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 10px;

  .header-left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .label {
    font-weight: bold;
    color: #007b83;
  }

  .category {
    font-size: 0.9rem;
    color: #007b83;
    padding: 2px 8px;
    border-radius: 4px;
  }
}

.content {
  p {
    margin: 0 0 8px 0;
    color: #333;
  }

  .show-image-button {
    background-color: #007b83;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 8px 12px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #005a5f;
    }
  }

  .scores {
    display: flex;
    gap: 16px;
    margin-top: 12px;

    .score {
      width: 100px;

      .tooltip {
        margin-left: 5px;
        cursor: help;
      }

      label {
        font-size: 0.9rem;
        color: #333;
        margin-bottom: 4px;
      }
    }
  }
}

.feedback-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  span {
    font-size: 1.2rem;
  }

  textarea {
    flex: 1;
    margin-left: 12px;
    border-radius: 6px;
  }
}

.thumbs-emoji {
  cursor: pointer;
  border: none;
  background: transparent;
  margin: 0 3px;
}

.submit-button {
  background-color: #007b83;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005a5f;
  }
}

.canvas-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reset-button {
  padding: 8px 16px;
  background-color: #007b83;
  border: 1px solid #d9d9d9;
  color: white;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    background-color: #d9d9d9;
    color: #333;
    cursor: default;
  }

  &.active {
    background-color: #1890ff;
    color: white;
  }

  & + & {
    margin-left: 8px;
  }
}

.canvas-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;
}

.canvas {
  width: 100%;
  height: 100%;
  background-color: white;
  object-fit: contain;
}

.reviewed-message {
  font-size: 14px;
  font-weight: bold;
  color: green;
  margin-left: 20px;
  position: absolute;
  right: 40px;
}

.desired-feedback {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;

  h4 {
    margin-bottom: 12px;
    font-weight: 500;
  }

  .desired-input {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.match-message {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;

  h2 {
    margin-bottom: 20px;
    color: #333;
  }
}

.modal-content {
  .input-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: 500;
    }

    input, select, textarea {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;

      &:focus {
        outline: none;
        border-color: #40a9ff;
      }
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }
  }
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;

  button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;

    &:first-child {
      background-color: #1890ff;
      color: white;

      &:hover {
        background-color: #40a9ff;
      }
    }

    &:last-child {
      background-color: #f5f5f5;
      color: #333;

      &:hover {
        background-color: #e8e8e8;
      }
    }
  }
}

.average-scores {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 20px;
  border-top: 1px solid #e8e8e8;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-top: auto;
}

.label-suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.label-suggestion {
  padding: 4px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  background: white;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: #1890ff;
    color: #1890ff;
  }

  &.selected {
    background: #1890ff;
    color: white;
    border-color: #1890ff;
  }
}

.human-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1890ff;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;

  &:hover {
    background-color: #40a9ff;
  }
}
