.skeleton-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: white;

  &__image {
    height: 250px;
    background-color: #e5e7eb;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  &__content {
    padding: 1rem;

    &-title {
      height: 1.5rem;
      background-color: #e5e7eb;
      border-radius: 0.25rem;
      width: 75%;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      margin-bottom: 0.75rem;
    }

    &-text {
      height: 1rem;
      background-color: #e5e7eb;
      border-radius: 0.25rem;
      width: 50%;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      margin-bottom: 0.75rem;
    }
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
